<template></template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default
{
  created()
  {
    this.fetchData();
  },
  beforeRouteUpdate(to, from, next)
  {
    this.fetchData();
    next();
  },
  methods:
  {
    ...mapActions(['showProduct', 'getBusinessProducts']),
    fetchData()
    {
      this.showProduct({
        lang: this.$i18n.locale,
        product_id: this.$route.params.productId
      }).then(res => {
        let ProName = this.product.translations[0].name;
        // console.log(ProName.replace(/ /g, '_').toLowerCase())
        this.$router.replace({
          name: 'ProductList',
          params: {
            lang: this.$i18n.locale,
            product_id: this.$route.params.productId,
            PName:  ProName.replace(/ /g, '_').toLowerCase(),
            show: this.$route.params.show
          }
         
        })
      })
        .catch(err => console.error(err));
    }
  },

  computed: {
    ...mapGetters(['product', 'width', 'loading']),
    
  }
}
</script>